
import { useDetectAdBlock } from "adblock-detect-react";
import React, { useState, useEffect } from 'react';
import { Ring, LineWobble } from '@uiball/loaders'
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  RedditShareButton,
  TumblrShareButton,
  TwitterShareButton,
  WorkplaceShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  LivejournalIcon,
  RedditIcon,
  TumblrIcon,
  TwitterIcon,
  WorkplaceIcon
} from "react-share";

export default function Popups() {
  const detectAdBlock = useDetectAdBlock()
  const shareDivOrigin = -200
  const cookieDivOrigin = -500
  const [showAdBlock, setAdBlock] = useState(false);
  const [cookiePopup, setCookie] = useState(true);
  const [showNewsLetter, setNewsLetter] = useState(false);
  const [enteredEmail, setEntered] = useState('');
  const [loadToggle, setLoad] = useState(true);
  const [showFakeSite, setFakeSite] = useState(true);
  const [sharePosition, setSharePosition] = useState(shareDivOrigin);
  const [sharePopup, setSharePopup] = useState(true);
  const [cookiePosition, setCookiePosition] = useState(cookieDivOrigin);
  const [moneyPopup, setMoneyPopup] = useState(false);
  const [background, setBackground] = useState(false);
  const [validEmail, setValid] = useState(false)
  const [emailAddress, setAddress] = useState("")
  const [giftAmount, setGiftAmount] = useState("")
  const [acceptableAmount, setAcceptableAmount] = useState(false)
  const [giftInner, setGiftInner] = useState(true)
  const [paid, setPaid] = useState(false)
  const [failedPay, setFailedPay] = useState(false)
  const [checkedBox, setCheckBox] = useState([true, true, true, true, true])
  let cookies, letter, share, money = true
  let block = false

  useEffect(() => {
    startup();
  }, []);


  function masterToggle(popup, toggle) {
    // console.log(popup)
    switch(popup) {
      case "cookies":
        setCookie(toggle)
        cookies = toggle
        if (toggle) {
          comeUp()
        } else {
          setCookiePosition(cookieDivOrigin)
        }
        break;
      case "letter":
        if (!emailAddress || !toggle) { 
          setNewsLetter(toggle)
          letter = toggle
        }
        break;
      case "share":
        setSharePopup(toggle)
        letter = toggle
        if (toggle) {
          dipDown()
        } else {
          setSharePosition(shareDivOrigin)
        }
        break;
      case "money":
        if (!paid || !toggle) {
          setMoneyPopup(toggle)
          setGiftInner(toggle)
          money = toggle
        }
        break;
      case "adblock":
        setAdBlock(toggle)
        block = toggle
        break;
      default:
        break;
    }
  }

  async function Toggle(step) {
    // console.log(step)
    switch(step) {
      case 1:
        masterToggle("cookies", false)
        masterToggle("letter", true)
        randToggle(step)
        break;
      case 2:
        masterToggle("letter", false)
        masterToggle("money", true)
        masterToggle("share", true)
        randToggle(step)
        break;
      case 3:
        masterToggle("letter", false)
        masterToggle("money", false)
        masterToggle("share", false)
        setBackground(true)
        randToggle(step)
        break;
      case 4:
        masterToggle("share", false)
        randToggle(step)
        break
      default:
        break;
    }
    if (!cookies && !letter && !share && !money && !block) {
      if (detectAdBlock) {
        masterToggle("adblock", true)
      } else {
        finishLine()
      }
    }
  }

  function randToggle(i) {
    let n = Math.floor(Math.random() * 3)
    console.log(i, n)
    if (n === i || (detectAdBlock && showAdBlock)) {
      return
    }
    switch(n) {
      case 1:
        console.log("Activate cookies")
        if (!checkedCookies()) {
          masterToggle("cookies", true)
        }
        break;
      case 2:
        // console.log("Activate newsletter")
        masterToggle("letter", true)
        break;
      case 3:
        let x = Math.floor(Math.random() * 2)
        if (x === 1) {
          // console.log("Activate money")
          masterToggle("money", true)
        } else {
          // console.log("Activate share")
          masterToggle("share", true)
        } 
        break;
      default:
        break;
    }
  }

  async function moneyToggle(alt) {
    setGiftInner(false)
    switch(alt) {
      case 1:
        setPaid(false)
        await timeout(2000)
        Toggle(3)
        break;
      case 2:
        setPaid(true)
        await timeout(5000)
        setFailedPay(true)
        await timeout(2000)
        Toggle(3)
        break;
      default:
        break;
    }
  }

  function checkedCookies() {
    let allChecked = true
    for (let cookie of checkedBox) {
      if (!cookie) {
        allChecked = false
      }
    }
    return allChecked
  }

  function killWindows() {
    if (sharePopup && sharePosition === 0) {
      Toggle(4)
    }
  }

  async function finishLine() {
    await timeout(5000)
    setFakeSite(false)
    setLoad(false)
  }

  async function dipDown() {
    let timeoutLimit = 5
    for (let i = sharePosition; i < 1; i++) {
      await timeout(timeoutLimit)
      if (i > -20) {
        timeoutLimit += 1
      }
      setSharePosition(i)
    }
  }

  async function comeUp() {
    let timeoutLimit = 1
    for (let i = -500; i < 1; i = i + 6) {
      await timeout(timeoutLimit)
      if (i > -30) {
        timeoutLimit += 2
      }
      setCookiePosition(i)
    }
  }

  async function startup() {
    await timeout(1000)
    masterToggle("cookies", true)
    await timeout(6000)
    masterToggle("letter", true)
  }

  function timeout(delay) {
    return new Promise( res => setTimeout(res, delay) );
  }



  const fakeSite = (
    <div id="fakeSite" onClick={() => killWindows()}>
    </div>
  )

  const loader = (
      <div className="load">
        <div className="loader" onClick={() => killWindows()}>
          <Ring size={60} color="white" />
        </div>
      </div>
  )

  const endScreen = (
    <div className="notFound">
      <p>Innehållet kunde inte hittas, testa ladda om sidan!</p>
    </div>
  )

  const checkUncheck = (e) => {
    let boxes = checkedBox
    let index = parseInt(e.currentTarget.name)
    let randomBox = Math.floor(Math.random() * 5)
    if (randomBox !== index && randomBox < 5) {
      let id = "box-" + randomBox.toString()
      console.log(id)
      document.getElementById(id).checked = true;
      boxes[randomBox] = true
    }
    boxes[index] = !checkedBox[index]
    setCheckBox(boxes)
  }; 

  const cookiePrompt = (
    <div style={{bottom: cookiePosition + "px"}} id="cookiePrompt">
      <h1>På röv.nu respekterar vi din personliga integritet*</h1>
      <div id="cookieBody">
        <p>För att röv.nu ska fungera optimalt behöver vi använda oss av cookies. Vi använder oss av cookies för att göra det enklare för dig att interagera med vår hemsida, 
          och enklare för oss att presentera dig med den absolut mest vidriga reklamen du kan tänka dig. Utan cookies hade vi varit tvungna att visa generisk reklam, och hade på 
          så vis riskerat att du ser något som inte är maximalt anpassat för att vara irriterande för specifikt just dig.</p>
        
        <p>Cookies låter oss också sammanställa en i sanning chockerande närgången profil av dig som person som vi sedan säljer vidare till bokstavligt talat vem som helst 
          som erbjuder oss den minsta möjliga summa pengar för den. Vi bryr oss verkligen inte ett dugg vem det är. Svindlare, gängbrottslingar, Saudiska staten eller ditt 
          stalkande ex, så länge de har pengar är de våra bästa vänner.</p>
        
        <p>För att inte riskera att få en absolut käftsmäll av kukhuvudena i Bryssel måste vi dock erbjuda dig möjligheten att tacka nej till kakor. Vi försökte förklara för 
          dem att utan kakor så kan vi inte tjäna den maximala mängden pengar, vilket gör oss otroligt ledsna, men inte fan lyssnade de. Rövhål. </p>
        
        <p>Så välj själv hur många gig trackingkakor vi får fylla din dator med, men du ska veta att vi inte gillar det.</p>
      </div>
      <div id="selectDiv">
        <label>
          <input name="0" id="box-0" type="checkbox" defaultChecked={checkedBox[0]} onChange={checkUncheck}/>
            Nödvändiga cookies
        </label>
        <label>
          <input name="1" id="box-1" type="checkbox" defaultChecked={checkedBox[1]} onChange={checkUncheck}/>
            Onödiga cookies
        </label>
        <label>
          <input name="2" id="box-2" type="checkbox" defaultChecked={checkedBox[2]} onChange={checkUncheck}/>
            Reklam-cookies
        </label>
        <label>
          <input name="3" id="box-3" type="checkbox" defaultChecked={checkedBox[3]} onChange={checkUncheck}/>
            Cookies som kommer följa med dig till graven
        </label>
        <label>
          <input name="4" id="box-4" type="checkbox" defaultChecked={checkedBox[4]} onChange={checkUncheck}/>
            Cookies vi slängt in bara för att
        </label>
        <br></br>
        <button onClick={() => Toggle(1)}>
        Verifiera inställningar
        </button>
      </div>
      <p id="finePrint">* Det här uttalandet är för PR-syften enbart och reflekterar på inget sätt röv.nu’s faktiska åsikter. Fuck din integritet.</p>
    </div>
  )

  

  const adblock = (
    <div id="adblock">
      <div id="content">
      <h1>Avaktivera din jävla adblocker</h1>
      <p>Tror du det är gratis att driva en fucking webbplats, är det vad du tror? Vi behöver reklampengarna ditt jävla snyltar-as, så nu är det du
        som avaktiverar din adblocker fort som själva fan och laddar om hemsidan. 
      </p>
      <p>
        Okej? Okej.
      </p>
      </div>
    </div>
  )

  const url = "https://röv.nu/"
  const hashtag = "#röv"
  const shareSubject = "Besök röv.nu idag!"
  const shareBody = "Sen jag besökte röv.nu har mitt liv blivit 80-120% bättre! Jag kan inte nog rekommendera att du besöker röv.nu och ger dem alla dina betalningsuppgifter!"
  const iconSize = 40


  const shareDiv = (
    <div style={{top: sharePosition + "px"}} id="shareDiv">
      <h3>Glöm inte att berätta för dina vänner om röv.nu!</h3>
      <EmailShareButton url={url} subject={shareSubject} body={shareBody}><EmailIcon size={iconSize} round={true} /></EmailShareButton>
      <FacebookShareButton url={url} hashtag={hashtag} quote={shareBody}><FacebookIcon size={iconSize} round={true} /></FacebookShareButton>
      <LinkedinShareButton url={url} source={url} title={shareSubject} summary={shareBody}><LinkedinIcon size={iconSize} round={true} /></LinkedinShareButton>
      <LivejournalShareButton url={url} title={shareSubject} description={shareBody}><LivejournalIcon size={iconSize} round={true} /></LivejournalShareButton>
      <RedditShareButton url={url} title={shareSubject} ><RedditIcon size={iconSize} round={true} /></RedditShareButton>
      <TumblrShareButton url={url} title={shareSubject} caption={shareBody}><TumblrIcon size={iconSize} round={true} /></TumblrShareButton>
      <TwitterShareButton url={url} hashtag={hashtag} title={shareSubject}><TwitterIcon size={iconSize} round={true} /></TwitterShareButton>
      <WorkplaceShareButton url={url} hashtag={hashtag} quote={shareBody}><WorkplaceIcon size={iconSize} round={true} /></WorkplaceShareButton>
    </div>
  )


  function isNumeric(str) {
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
           !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
  }

  const handleAmountChange = (event) => {
    let value = event.target.value
    if (isNumeric(value) || value === "") {
      setGiftAmount(value);
      if (parseInt(value) > 100) {
        setAcceptableAmount(true)
      } else {
        setAcceptableAmount(false)
      }
    } else {
      setAcceptableAmount(false)
    }
};

  const giveMoney = (
    <div id="giveDiv">
      {giftInner && ( 
      <div>
      <h1>Ge oss pengar!</h1>
      <p>Röv.nu är beroende av hjälp från användare som du. Utan ditt ekonomiska stöd kommer ägarkoncernen bakom moderbolaget som äger företaget som driver
        röv.nu inte kunna plocka ut en tillräckligt hög vinst för att motivera den fortsatte driften av hemsidan. När du skänker pengar till oss kan du vara
        säker på att varje krona går oavkortat till aktieutdelning och bonusar!
      </p>
      <form>
      <span><input type="text" id="giftField" name="giftField" value={giftAmount} onChange={handleAmountChange}></input>kr</span><br></br>
      </form>
      <button disabled={!acceptableAmount} onClick={() => moneyToggle(2)}>
        Skänk pengar!
      </button>
      <button onClick={() => moneyToggle(1)}>
        Jag är snål
      </button>
      </div>
      )}
      {(!giftInner && !paid) && (
      <div>
      <h1>Vi kommer att minnas ditt val.</h1>
      <p>Snålröv.</p>
      </div>
      )}
      {(!giftInner && paid && !failedPay) && (
        <div id="payLoader">
          <div id="payLoaderInner">
          <LineWobble size={80} color="black" />
          </div>
        </div>
      )}
      {(!giftInner && failedPay) && (
      <div>
        <h1>Någonting gick fel!</h1>
      </div>
      )} 
    </div>
  )

  const emailMatch = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  const handleChange = (event) => {
      setEntered(event.target.value);
      if (event.target.value.match(emailMatch)) {
        setValid(true)
      }else{
        setValid(false)
      }
  };


function signup(adress) {
  setAddress(adress)
  Toggle(2)
}

  const newsLetter = (
    <div id="newsLetter">
      <div id="top">
        <p id="exit" onClick={() => Toggle(2)}>X</p>
      </div>
      <h1>Skriv upp dig på röv.nu's reklamutskick!</h1>
      <p>Missa inte chansen att få riktad reklam om vad fan det nu är vi håller på med direkt i din inkorg! Du kommer få fem till tolv mejl i veckan och radera
        varenda ett utan att läsa dem. När du väl inser att du borde avregistrera dig om typ två år kommer du med fasa inse att vi skrivit upp dig på åtta olika mejllistor
        och kräver att du säger upp dig från var och en separat. Och som bonus kommer vi sälja din mejladress till ett företag som gör marknadsundersökningar om cialis
        och håravfallsbehandling. 
        </p>
        <p>
        Signa upp idag! Annars frågar vi igen nästa gång du besöker vår hemsida.
      </p>
      <form>
      <input type="text" id="newsletterField" name="newsletterField" value={enteredEmail} onChange={handleChange}></input><br></br>
      </form>
      <button disabled={!validEmail} onClick={() => signup(enteredEmail)}>
        Registrera dig!
      </button>
    </div>
  )




  return (
    <div className="popups">
      {(sharePopup &&  !(detectAdBlock && showAdBlock)) && shareDiv}
      {(detectAdBlock && showAdBlock) && adblock}
      {(cookiePopup && !(detectAdBlock && showAdBlock)) && cookiePrompt}
      {(showNewsLetter && !moneyPopup && !showAdBlock) && newsLetter}
      {moneyPopup && giveMoney}
      {loadToggle && loader}
      {showFakeSite && fakeSite}
      {background && endScreen}
    </div>
  );
}



